import * as React from "react";
import {useCityList} from "../hooks/use-city-list";
import {GatsbyImage} from "gatsby-plugin-image";

import Popout from "./city-select";

export default function HubCities(props: any) {
  const cityList = useCityList();
  const cityListHub = cityList?.filter((oneCity, index) => {
    return !oneCity.isHub && oneCity?.hub?.urlPart == props.hubUrl;
  });
  return (
    <div className="block-wrapper pt-5 pb-5">
      <div className="row no-gutters">
        <div className="col-12 bg-transparent text-center">
          <div className="hub-city hub-city--box bg-white">
            <h2>Select your area</h2>
            <Popout
              cities={cityListHub}
              id={'hubCity'}
            />
            {!props.imageOptimized &&
              <img src={props.hubImage} placeholder="blurred" alt="Select your area" loading="lazy"/>}
            {props.imageOptimized &&
              <GatsbyImage style={{display: "block"}} image={props.imageOptimized?.childImageSharp?.gatsbyImageData}
                           alt="Select your area" loading="lazy"/>}
          </div>
        </div>
      </div>
    </div>
  );
}