import * as React from "react";
import {useState, useEffect} from "react";
import {getImage, withArtDirection} from "gatsby-plugin-image";
import {graphql, HeadProps, PageProps} from "gatsby"
import Cookies from "universal-cookie";

import Layout from "../components/layout";
import Hero from "../components/hero";
import HeroContentHeading from "../components/hero-content-heading";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import BlockMap from "../components/blocks/block-map";
import MapSingle from "../components/map/single-fixed";
import Section from "../components/section";
import HubCities from "../components/hub-cities";
import {useCityList} from "../hooks/use-city-list";
import Block8Right from "../components/blocks/block-8right";
import Popout from "../components/city-select";
import CityHubTile from "../components/city-hub-tile";
import TemplateHead from "../components/template-head";

const cookies = new Cookies();

type DataProps = {
  strapiCity: any,
  strapiAdvisor: any
  defaultHeroMobileBg: any
}

export default function CityPage({data: {strapiCity, strapiAdvisor, defaultHeroMobileBg}}: PageProps<DataProps>) {
  const [callCityModal, setCallCityModal] = useState(true);
  cookies.remove("fenix4city", {path: '/'});

  const city = strapiCity;
  useEffect(() => {
    const cookieHub = cookies.get("fenix4hub");
    let d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    let expiresTime = d;
    if (cookieHub !== city.cityUrl) {
      cookies.set("fenix4hub", city.cityUrl, {
        expires: expiresTime,
        path: "/",
      });
      cookies.set("fenix4label", city.title, {
        expires: expiresTime,
        path: "/",
      });
    }
  }, [city]);

  const imagesHero = withArtDirection(getImage(city.image?.hero?.localFile), [
    {
      media: "(max-width: 719px)",
      image: getImage(city.image?.small2x?.localFile),
    },
    {
      media: "(max-width: 1199px)",
      image: getImage(city.image?.middle?.localFile),
    },
  ])

  const cityList = useCityList();
  const cityListHub = cityList?.filter((oneCity, index) => {
    return !oneCity.isHub && oneCity?.hub?.urlPart === city.cityUrl;
  });

  const advisor = strapiAdvisor

  const pageTextWelcome = (
    <div dangerouslySetInnerHTML={{__html: city.text?.textWelcome}}></div>
  );

  const pageExtraService = (
    <div dangerouslySetInnerHTML={{__html: city.text?.textSpecialService}}></div>
  );

  return (
    <Layout as="city"  hubSlug={city.hub.urlPart}>
      {callCityModal && (
        <div className="modal fade show">
          <div className="modal-dialog modal-dialog-centered hub-popup">
            <div className="modal-content">
              <button
                className="close"
                onClick={() => setCallCityModal(false)}
              >
                <img
                  src="/images/icons/icon_close-darkblue.svg"
                  alt="close button"
                />
              </button>
              <div className="modal-body">
                <HubCities
                  hubUrl={city?.hub?.urlPart}
                  hubTitle={
                    city?.hub?.title ? city?.hub?.title : ""
                  }
                  imageOptimized={city?.image?.small2x?.localFile}
                  hubImage={
                    city?.image?.small2x?.url
                      ? city.image.small2x.url
                      : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </div>
      )}
      <Hero
        imageOptimized={imagesHero}
        altImages={city.hub.title}
        sectionClassName="hero-section hero-section--city hero-section--hub pl-4 pr-4"
        bgImageClassName="hero-image hero-image--city"
        contentClassName="hero-content hero-content--city hero-content--hub"
        breadcrumb={[
          {name: "Fenix Home", slug: "/"},
          {
            name: city.heading.main
              ? city.heading.main
              : "Fenix Funeral Directors in " + city.hub.title,
            slug: "/funeral-directors/" + city.cityUrl + "/",
          },
        ]}
      >
        <HeroContentHeading
          headingTitle={
            city.heading.main
              ? city.heading.main
              : "Fenix Funeral Directors in " + city.hub.title
          }
          headingContent={"Trusted and affordable – from £ 995"}
          advisorData={advisor}
        >
        </HeroContentHeading>
        <BlockMap
          rowClass="row no-gutters flex-column-reverse flex-lg-row-reverse"
          col1Class="col-12 col-lg-4 col-bottom-right bg-white pl-4 pl-md-5 pr-4 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5"
          col2Class="col-12 col-lg-8 col-top-left"
          h2={
            city.text.headingWelcome
              ? city.text.headingWelcome
              : "Our funeral homes in" + city.hub.title
          }
          text={pageTextWelcome}
          map={
            <MapSingle
              key={`map-single-key`}
              lat={city.geo?.latitude}
              lng={city.geo?.longitude}
              city={city.title}
              cityData={city}
              isHub={true}
              heading={city.heading?.main}
              company={city.office?.corporateChain}
              address={city.office?.fullAddress}
              postcode={city.office?.postcode}
            />
          }
          citySelect={
            <Popout
              cities={cityListHub}
              id={"topbarSelectHubPost"}
            />
          }
        />
      </Hero>
      <Section
        sectionClass={
          "bg-deepblue pl-4 pr-4 section-container section-container--extra pt-5"
        }
      >
        <Block8Right
          h2={"Extra Requests"}
          text={city.text?.textSpecialService ? pageExtraService : ""}
        />
        <img
          src="/images/stripe_1.svg"
          alt="Fenix Funeral Directors"
          className="stripe stripe--1 stripe--city"
        />
      </Section>
      <TrustpilotCarousel/>
      <Section sectionClass="pl-4 pr-4 section-container section-container--tile">
        <CityHubTile
          hubTitle={city?.hub?.title}
          citiesList={cityListHub}
          defaultCityBg={defaultHeroMobileBg}
        />
      </Section>
    </Layout>
  );
}

export function Head({data: {strapiCity}}: HeadProps<DataProps>) {
  const city = strapiCity;
  return (
    <TemplateHead
      title={city.seo.metaTitle}
      shortlink={city.seo.canonicalURL}
      alternate={city.seo.canonicalURL}
      canonical={city.seo.canonicalURL}
      description={city.seo.metaDescription}
      og={{
        url: city.seo.canonicalURL,
        title: city.seo.metaTitle,
        type: "business.business",
        description: city.seo.metaDescription,
        image: city.image.hero.localFile.url,
        imageWidth: 600,
        imageHeight: 315
      }}
      business={{
        contact_data: {
          street_address: city.office?.fullAddress ? city.office.fullAddress : '',
          locality: city.title,
          region: city.county,
          postal_code: city.office?.postcode ? city.office?.postcode : '',
          country_name: "United Kingdom"
        }
      }}
      ldjson={
        `{
          "@context": "https://schema.org",
          "@id": "https://fenixfuneral.co.uk/#webpage",
          "url": "${city.seo.canonicalURL}",
          "@type": "LocalBusiness",
          "name": "Fenix Funeral Directors in ${city.title}",
          "priceRange": "£995 - £2280",
          "telephone": "0808 164 9040",
          "image":"${city.image.hero.url ? city.image.hero.url : ""}",
          "aggregateRating":{
              "@type":"AggregateRating",
              "ratingValue":"4.8",
              "reviewCount":"152"
          },
          "address":{
              "@type":"PostalAddress",
              "streetAddress":"${city.office?.fullAddress ? city.office.fullAddress : ''}",
              "addressLocality":"${city.title}",
              "addressRegion":"${city.hub?.title}",
              "postalCode":"${city.office?.postcode ? city.office?.postcode : ''}",
              "addressCountry":{
                  "@type":"Country",
                  "name":"United Kingdom"
              }
          }
          }`
      }
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "position": "1",
          "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"},
          {
          "@type": "ListItem",
          "position": "2",
          "name": "Fenix Funeral Directors in "","item": "${city.seo.canonicalURL}"
          }`
      }
    />
  );
}

export const pageQuery = graphql`
    fragment imageDataHubHero on STRAPI__MEDIA {
        url
        localFile {
            childImageSharp {
                gatsbyImageData(
                    placeholder: NONE
                    outputPixelDensities: 1
                )
            }
        }
    }
    query ($slug: String) {
        strapiCity(cityUrl: { eq: $slug }) {
            id
            strapi_id
            title
            cityUrl
            isHub
            office {
                corporateChain
                fullAddress
                postcode
            }
            geo {
                latitude
                longitude
            }
            seo {
                metaDescription
                metaTitle
                canonicalURL
            }
            text {
                headingPlan
                textPlan
                textSpecialService
                textWelcome
                headingWelcome
                textTabChurch
                textTabCemetery
            }
            heading {
                main
            }
            hub {
                id
                title
                urlPart
            }
            image {
                middle {
                    ...imageDataHubHero
                }
                hero {
                    alternativeText
                    url
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 1920
                                outputPixelDensities: 1
                                placeholder: NONE
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
                small2x {
                    ...imageDataHubHero
                }
            }
        }
        strapiAdvisor(url: {eq: "emily-cross"}) {
            id
            title
            url
            fullname
            description {
                data {
                    description
                }
            }
            photo2x {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 400,
                            height: 400,
                            placeholder: NONE,
                            outputPixelDensities: 1
                        )
                    }
                }
            }
        }
        defaultHeroMobileBg: allStrapiMedia(filter: {url: {eq: "https://nextpwcms.s3.eu-north-1.amazonaws.com/city_placeholder_2x_4fc0811750.jpg"}}) {
            edges {
                node {
                    url
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: NONE,
                                outputPixelDensities: 1
                            )
                        }
                    }
                }
            }
        }
    }
`;
