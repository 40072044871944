import * as React from "react";
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import ClickReveal from "./click-reveal";

export default function CityHubTile(props: any) {

  const allCities = props.citiesList

  return (
    <div className="block-wrapper">
      <div className="row">
        <div className="col-12">
          <h2 className="text-white mb-5">Funeral Homes in {props.hubTitle}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="tile-list d-flex flex-row flex-wrap justify-content-between">
            {allCities.map((item: any, index: any) => (
              <div key={index} className={'tile-item'}>
                <Link to={"/funeral-directors/" + item.hub.urlPart + "/" + item.cityUrl + "/"} title={item.title}>
                  <GatsbyImage
                    image={item.image?.middle?.localFile?.childImageSharp?.gatsbyImageData ? item.image?.middle?.localFile?.childImageSharp?.gatsbyImageData : props.defaultCityBg.edges[0]?.node?.localFile?.childImageSharp
                      ?.gatsbyImageData} alt={item.title} loading="lazy"/>
                </Link>
                <div className="tile-content">
                  <div className="tile-item__title text-left d-flex flex-column">
                    <Link to={"/funeral-directors/" + item.hub.urlPart + "/" + item.cityUrl + "/"} title={item.title}>
                      <h3 className="text-center">{item.title}</h3>
                    </Link>
                    {item.office && item.office.fullAddress ? <p className="tile-item__line tile-item__address">
                      <span>{item.office.fullAddress + ", " + item.office.postcode}</span></p> : ""}
                    <p className="tile-item__line tile-item__phone">
                      <ClickReveal
                        className="ctr-button ctr-button--tile"
                      />
                    </p>
                    <p className="tile-item__line tile-item__email"><a
                      href="mailto:hello@fenixfuneral.co.uk"><span>hello@fenixfuneral.co.uk</span></a></p>
                    <p className="tile-item__line tile-item__clock d-flex flex-column"><span>12:00 - 17:00</span><span>(only by online appointment)</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/*{hasMore ? (*/}
          {/*  <div className="d-flex justify-content-center mt-4">*/}
          {/*    <button className={'btn btn-transparent'} onClick={handleLoadMore}>Load More</button>*/}
          {/*  </div>*/}
          {/*) : (*/}
          {/*  ''*/}
          {/*)}*/}
        </div>
      </div>
    </div>
  );
}

